import './header.css'

import logo from './logoMain.png'
import chosenLogo from './chosenLang.png'
import menu from './menu.png'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useState } from 'react';

let stoper = true;

function togleHead(num) {
    let navBtn = document.getElementsByClassName('nav');
    for (let i = 0; i < navBtn.length; i++) {
        if (num === i) {
            if (!navBtn[i].classList.contains('activeBorder')) {
                navBtn[i].classList.toggle('activeBorder')
            }
        } else {
            if (navBtn[i].classList.contains('activeBorder')) {
                navBtn[i].classList.toggle('activeBorder')
            }
        }
    }
}
function HeaderBlock({getPosition}) {
    function isMobi() {
        if (window.innerWidth < 800) {
            document.getElementById('menuMobi').classList.toggle('mobiMenu');
        }
    }
    let pathname = window.location.pathname; //вернет /smartroulette-lp/index.php
    let result;
    console.log(window.location.href.includes('#formContact'));
    
    if (pathname === '/') {
        result = ['nav l0Margin activeBorder', 'nav', 'nav', 'nav', 'nav r0Margin'];
    } else if (pathname === '/about/') {
        result = ['nav l0Margin', 'nav activeBorder', 'nav', 'nav', 'nav r0Margin'];
    } else if (pathname === '/products/') {
        result = ['nav l0Margin', 'nav', 'nav activeBorder', 'nav', 'nav r0Margin'];
    } else if (pathname === '/services/') {
        result = ['nav l0Margin', 'nav', 'nav', 'nav activeBorder', 'nav r0Margin'];
    } else {
        result = ['nav l0Margin', 'nav', 'nav', 'nav', 'nav r0Margin'];
    }
    let [activeBorder, getActiveBorder] = useState(result);
    
    console.log(activeBorder[1])
    return (
        <>
            <header>
                <img src={menu} alt="" className='menu left'/>
                <img src={logo} alt="" srcset=""/>
                <img src={menu} alt="" className='menu right' 
                    onClick={
                        () => {
                            document.getElementById('menuMobi').classList.toggle('mobiMenu')
                        }
                    }
                />
                <div id='menuMobi'>
                    <nav>
                        <div className={activeBorder[0]}>
                            <HashLink to='/#' onClick={() => {
                                togleHead(0);
                                isMobi();
                            }}>HOME</HashLink>
                        </div>
                        <div className={activeBorder[1]}>   
                            <HashLink to='/about/#' onClick={() => {
                                togleHead(1);
                                isMobi();
                            }}>ABOUT US</HashLink>
                        </div>
                        <div className={activeBorder[2]}>
                            <HashLink to='/products/#' onClick={() => {
                                togleHead(2);
                                isMobi();
                            }}>PRODUCTS</HashLink>
                        </div>
                        <div className={activeBorder[3]}>
                            <HashLink to='/services/#' onClick={() => {
                                togleHead(3);
                                isMobi();
                            }}>SERVICES</HashLink>
                        </div>
                        <div className={activeBorder[4]}>
                            <HashLink to='/#formContact' onClick={() => {
                                togleHead(4);
                                isMobi();
                            }}>CONTACTS</HashLink>
                        </div>
                    </nav>
                    <div id='lang'>
                        <div className='containerLang'>
                            <img src={chosenLogo} alt="" srcset="" />
                            <span>EN</span>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default HeaderBlock;



/*if (window.location.href.includes('#formContact')) {
        result = ['nav l0Margin', 'nav', 'nav', 'nav', 'nav r0Margin activeBorder']
        console.log(window.location.href.includes('#formContact'));
    }else */
import './service.css';
import plane from './plane.png';
import boat from './boat.png';
import storage from './storage.png';
import { HashLink } from 'react-router-hash-link';

function Service({changeBlock, getPosition}) {
    function togleHead(num) {
        let navBtn = document.getElementsByClassName('nav');
        for (let i = 0; i < navBtn.length; i++) {
            if (num === i) {
                if (!navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            } else {
                if (navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            }
        }
    }
    return (
        <div id="Service">
            <h6>SERVICES</h6>
            <div className='servBase'>
              <img src={boat} alt="" />
              <div className='servTextBase'>
                <h1>BUILDING MATERIALS<br/>SUPPLY CHAINS</h1>
                <p>We specialize in servicing companies operating in the building materials industry. Our expertise in logistics and warehousing allows us to optimize processes in the supply chain.</p>
                <div>
                    <div className='leftServ'>
                        <p><strong>Supply optimization</strong></p>
                        <p>We help optimize the procurement and supply of construction materials to improve efficiency and reduce costs.</p>
                    </div>
                    <div className='center'>
                        <p><strong>Inventory management</strong></p>
                        <p>Our warehouse solutions allow you to efficiently manage your inventory and ensure it arrives on time.</p>
                    </div>
                    <div className='rightServ'>
                        <p><strong>Accuracy and reliability</strong></p>
                        <p>We guarantee precision and reliability in the processing and delivery of building materials.</p>
                    </div>
                </div>
                <button>
                    <HashLink to='/#formContact' onClick={() => {
                        togleHead(4);
                    }}>Contact Us</HashLink>
                </button>
              </div>
            </div>
            <div className='servBase reversMobi'>
              <div className='servTextBase blackGra'>
                <h1 className='blackGra'>MULTIMODAL LOGISTIC<br/>SERVICES</h1>
                <p className='blackGra'>We provide multimodal cargo transportation services, combining various modes of transport for optimal cargo delivery:</p>
                <div>
                    <div className='leftServ'>
                        <p><strong className='blackGra'>Sea transportation</strong></p>
                        <p className='blackGra'>We organize delivery of goods around the world using container and pallet shipping.</p>
                    </div>
                    <div className='center wh'>
                        <p><strong className='blackGra'>Rail transportation</strong></p>
                        <p className='blackGra'>We provide rail transportation services using wagons and container platforms.</p>
                    </div>
                    <div className='rightServ'>
                        <p><strong className='blackGra'>Road transportation</strong></p>
                        <p className='blackGra'>To ensure efficiency, we carry out international delivery of goods using modern transport.</p>
                    </div>
                </div>
                <button>
                    <HashLink to='/#formContact' onClick={() => {
                        togleHead(4);
                    }}>Contact Us</HashLink>
                </button>
              </div>
              <img src={plane} alt="" />
            </div>
            <div className='servBase'>
              <img src={storage} alt="" />
              <div className='servTextBase'>
                <h1>WAREHOUSE<br/>SERVICES</h1>
                <p>Our modern warehouses provide secure storage and inventory management. We offer flexible solutions to meet the needs of each customer:</p>
                <div>
                    <div className='leftServ'>
                        <p><strong>Storage and inventory</strong></p>
                        <p>We provide safe storage and systematic inventory of goods in our warehouses.</p>
                    </div>
                    <div className='center'>
                        <p><strong>Packaging and labeling</strong></p>
                        <p>Our team of professionals packs and labels cargo to ensure its safety during storage and transportation.</p>
                    </div>
                    <div className='rightServ'>
                        <p><strong>Preparation for delivery</strong></p>
                        <p>We prepare cargo for delivery in accordance with client requirements and help ensure its timely arrival.</p>
                    </div>
                </div>
                <button>
                    <HashLink to='/#formContact' onClick={() => {
                        togleHead(4);
                    }}>Contact Us</HashLink>
                </button>
              </div>
            </div>
        </div>
    );
}

export default Service;
import './part1.css'
import lonlyMan from './lonlyMan.png'
function Part1() {
    
    return (
        <div id='Part1'>
            <img src={lonlyMan} alt="" />
            <div>
                <h1>AFDAL TARIQAH TRADING<br/>YOUR BUILDING MATERIALS EXPERT</h1>
                <h2>Reliability. Development. Experience.</h2>
            </div>
        </div>
    );
}

export default Part1;
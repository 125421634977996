import './footer.css';
import facebook from './FaceBook.png';
import instagram from './instagram.png';
import youTube from './youTube.png';
import lefLogo from './logoFooterEn.png';
import rightLogo from './logoFooterAr.png'

function Footer() {
    return (
        <footer>
            <div id='leftFoot'>
                <img src={lefLogo} alt="" />
                <div>
                    <p>Afdal Tariqah Trading L.L.C.</p>
                    <p>M46, Dawood Bldg,  Hor Al Anz, Dubai, UAE</p>
                    <p>P.O. Box: 126436</p>
                    <p>+971 55 346 7455</p>
                </div>
            </div>
            <div id='centerFoot'>
                <div>
                    <img src={instagram} alt="" />
                    <img src={facebook} alt="" className='centFoot'/>
                    <img src={youTube} alt="" />
                </div>
                <p>Terms and Conditions</p>
                <p>Privacy Policy</p>
                <p>© 2023 Afdal Tariqah Trading L.L.C. All rights reserved</p>
            </div>
            <div id='rightFoot'>
                <div>
                    <p><strong>افضل طريقه للتجارة ذ.م.م</strong></p>
                    <p>بناية داود، هور العنز، دبي، الإمارات العربية المتحدة، م٤٦  </p>
                    <p>ص.ب. صندوق: ١٢٦٤٣٦</p>
                    <p>+٩٧١٥٥٣٤٦٧٤٥٥</p>
                </div>
                <img src={rightLogo} alt="" />
            </div>
        </footer>
    );
}

export default Footer;
import './product.css';
import brick from './brick.png';
import panel from './panel.png';
import constr from './const.png';

function Product({changeBlock}) {
    return (
        <div id='Product'>
            <h1>PRODUCTS</h1>
            <div>
                <div className='trioProduct TILES'>
                    <img src={brick} alt="" />
                    <div>
                        CERAMIC TILES
                    </div>
                </div>
                <div className='trioProduct PANELS'>
                    <img src={panel} alt="" />
                    <div>
                        THERMAL PANELS
                    </div>
                </div>
                <div className='trioProduct MATERIALS'>
                    <img src={constr} alt="" />
                    <div>
                        CONSTRUCTION MATERIALS
                    </div>
                </div>
                
                
            </div>
        </div>
    );
}

export default Product;
import HeaderBlock from "./component/Header/HeaderBlock";
import './base.css'
import './Font/font.css'
import Home from "./component/Home/Home";
import Footer from "./component/Footer/Footer";
import AboutUs from "./component/AboutUs/AboutUs";
import Product from "./component/Product/Product";
import Service from "./component/Service/Service";
import { useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from 'react-router-dom';
import Gl from "./component/Gl/Gl";

function App() {
    
    let [position, getPosition] = useState(0)
    let changeBlock = (num) => {
        let allBlock = document.getElementsByClassName('cnt');
        if (allBlock.length === 0) return;
        console.log(allBlock);
        if (position === num && !allBlock[num].classList.contains("visBlcok")) {
            allBlock[num].classList.toggle('visBlcok')
        } else if (position !== num) {
            if (allBlock[num].classList.contains("visBlcok")) {
                allBlock[num].classList.toggle('visBlcok')    
            }
        }
        
    }
    return (
        <Router>
            <HeaderBlock getPosition={getPosition}/>
            <main>
                <Routes>
                    <Route 
                        path="/" 
                        element={<Home changeBlock={changeBlock} getPosition={getPosition}/>}
                    />
                    <Route 
                        path="/about" 
                        element={ <AboutUs changeBlock={changeBlock}/> } 
                    />
                    <Route 
                        path="/products" 
                        element={ <Product changeBlock={changeBlock}/>} 
                    />
                    <Route 
                        path="/services" 
                        element={ <Service changeBlock={changeBlock} getPosition={getPosition}/>} 
                    />
                    <Route 
                        path="/contact/AlexanderG"
                        element={<Gl />}
                    />
                </Routes>
            </main>
            <Footer />
        </Router>
    );
}

export default App;
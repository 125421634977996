import './part2.css';
import chairs from './chairs.png'
import { HashLink } from 'react-router-hash-link';
function Part2({getPosition}) {
    function togleHead(num) {
        let navBtn = document.getElementsByClassName('nav');
        for (let i = 0; i < navBtn.length; i++) {
            if (num === i) {
                if (!navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            } else {
                if (navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            }
        }
    }
    return (
        <div id="Part2">
            <img src={chairs} alt="" />
            <div>
                <div>
                    <h3>About Us</h3>
                    <p>Afdal Tariqah Trading LLC is a company engaged in international wholesale trade of construction materials. We organize supply chains for the comprehensive supply of construction projects from excavation work and foundation construction to building façade cladding and interior finishing work.</p>
                </div>
                <button>
                    <HashLink to='/about/#' onClick={() => {
                        togleHead(0);
                    }}>LEARN MORE</HashLink>
                </button>
            </div>
        </div>
    );
}

export default Part2;
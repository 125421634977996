import './gl.css'

import ceoPhoto from '../AboutUs/ceo.png'

import vCard from './vcard.gif'
import linkVcard from './Gladilin Afdal.vcf'

import linkToSite from './link afdal.svg'

import whatsApp from './WhatsApp.svg'
import mail from './Mail.png'
import telegram from './telegram.svg'
import { HashLink } from 'react-router-hash-link';

function togleHead(num) {
    let navBtn = document.getElementsByClassName('nav');
    for (let i = 0; i < navBtn.length; i++) {
        if (num === i) {
            if (!navBtn[i].classList.contains('activeBorder')) {
                navBtn[i].classList.toggle('activeBorder')
            }
        } else {
            if (navBtn[i].classList.contains('activeBorder')) {
                navBtn[i].classList.toggle('activeBorder')
            }
        }
    }
}

function Gl() {
    return (
        <>
            <div id="ContactPerspne">
                <img src={ceoPhoto} alt="" />
                <div>
                    <h1>Alexander Gladilin</h1>
                    <h2>CEO</h2>
                    <div className='cntAll'>
                        <div>
                            <a href={linkVcard}>
                                <img src={vCard} alt="" />
                                <h3>Save business card</h3>
                            </a>
                        </div>
                        <div>
                            <a href="mailto:a.gladilin@afdaltariqah.ae">
                                <img src={mail} alt="" />
                                <h3>Write by email</h3>
                            </a>
                        </div>
                        <div>
                            <HashLink to='/#' onClick={() => {
                                togleHead(0);
                            }}>
                                <img src={linkToSite} alt="" />
                                <h3>Visit site</h3>
                            </HashLink>
                        </div>
                        <div>
                            <a href="https://wa.me/971553467455">
                                <img src={whatsApp} alt="" />
                                <h3>Write to WhatsApp</h3>
                            </a>
                        </div>
                        <div>
                            <a href="https://t.me/Gladilin_Afdal_Tariqah">
                                <img src={telegram} alt="" />
                                <h3>Write to Telegram</h3>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Gl;
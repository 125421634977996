import './part4.css';
import chain from './chain.png';
import dilivery from './dilivery.png';
import storage from './storage.png'
import { HashLink } from 'react-router-hash-link';

function Part4({getPosition}) {
    function togleHead(num) {
        let navBtn = document.getElementsByClassName('nav');
        for (let i = 0; i < navBtn.length; i++) {
            if (num === i) {
                if (!navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            } else {
                if (navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            }
            
        }
    }
    
    return (
        <div className="PartTrio whiteTrio">
            <h4>SERVICES</h4>
            <div>
                <div>
                    <img src={chain} alt="" />
                    <p className='white'>BUILDING MATERIALS<br/>SUPPLY CHAINS</p>
                    <button className='white learnMore'>
                        <HashLink to='/services/#' onClick={() => {
                                togleHead(3);
                        }}>LEARN MORE</HashLink>
                    </button>
                </div>
                <div>
                    <img src={dilivery} alt="" />
                    <p className='white'>MULTIMODAL LOGISTIC<br/>SERVICES</p>
                    <button className='white learnMore'>
                        <HashLink to='/services/#' onClick={() => {
                                togleHead(3);
                        }}>LEARN MORE</HashLink>
                    </button>
                </div>
                <div>
                    <img src={storage} alt="" />
                    <p className='white'>WAREHOUSE SERVICES<br/><span className='invisFont'>d</span></p>
                    <button className='white learnMore'>
                        <HashLink to='/services/#' onClick={() => {
                                togleHead(3);
                        }}>LEARN MORE</HashLink>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Part4;
import Part1 from "./Part1/Part1";
import Part2 from "./Part2/Part2";
import Part3 from "./Part3/Part3";
import Part4 from "./Part4/Part4";
import Part5 from "./Part5/Part5";

function Home({changeBlock, getPosition}) {
    return (
        <div id="Home">
            <Part1 />
            <Part2 getPosition={getPosition}/>
            <Part3 getPosition={getPosition}/>
            <Part4 getPosition={getPosition}/>
            <Part5 />
        </div>
    );
}

export default Home;
import './part3.css';
import dimond from './diamond.png';
import brick from './brick.png';
import crane from './crane.png';

import { HashLink } from 'react-router-hash-link';
function Part3({getPosition}) {
    function togleHead(num) {
        let navBtn = document.getElementsByClassName('nav');
        for (let i = 0; i < navBtn.length; i++) {
            if (num === i) {
                if (!navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            } else {
                if (navBtn[i].classList.contains('activeBorder')) {
                    navBtn[i].classList.toggle('activeBorder')
                }
            }
        }
    }
    return (
        <div className="PartTrio">
            <h3>PRODUCTS</h3>
            <div>
                <div>
                    <img src={dimond} alt="" />
                    <p>CERAMIC TILES</p>
                    <button className='gray learnMore'>
                        <HashLink to='/products/#' onClick={() => {
                                togleHead(2);
                        }}>LEARN MORE</HashLink>
                        </button>
                </div>
                <div>
                    <img src={brick} alt="" />
                    <p>THERMAL PANELS</p>
                    <button className='gray learnMore'>
                        <HashLink to='/products/#' onClick={() => {
                                togleHead(2);
                        }}>LEARN MORE</HashLink>
                    </button>
                </div>
                <div>
                    <img src={crane} alt="" />
                    <p>CONSTRUCTION<br/>MATERIALS</p>
                    <button className='gray learnMore'>
                        <HashLink to='/products/#' onClick={() => {
                                togleHead(2);
                        }}>LEARN MORE</HashLink>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Part3;
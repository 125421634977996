import './part5.css'

function Part5() {
    return (
        <div className="" id="contact">
            <div id="map" className="section">
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d901.9483891116378!2d55.334104269654155!3d25.277529211297917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE2JzM5LjEiTiA1NcKwMjAnMDUuMSJF!5e0!3m2!1sru!2sru!4v1698689733463!5m2!1sru!2sru" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
            </div>
            <div id="formContact" className="section" >
                <h5>CONTACT US</h5>
                <p>M46, Dawood Bldg,  Hor Al Anz, Dubai, UAE</p>
                <p>tel. +971 55 346 7455</p>
                <p>info@afdaltariqah.ae</p>
                <form action="" method="post">
                    <p>
                        <label htmlFor="name">Name</label><br/>
                        <input type="text"  name="name" placeholder="Please enter your name" required />
                    </p>
                    <p>
                        <label htmlFor="email">Email</label><br/>
                        <input type="email" name="email" placeholder="Please enter your email" required /><br/>
                    </p>
                    <p>
                        <label htmlFor="tel">Phone</label><br/>
                        <input type="tel" name="tel" placeholder="Please enter your phone" required /><br/>
                    </p>
                    <p>
                        <label htmlFor="message">Type your question here</label><br/>
                        <textarea name="message" co="40" rows="6" required ></textarea>
                    </p>
                    <input id='bezs' name="bezspama" type="text" />
                    <p>
                        <button type="submit" name="message_frm">Send a message</button>
                    </p>
                    <p id="police"><a href="./privacy.html">By clicking on the "Send message" button, I consent to the processing of my personal data.</a>.</p>
                </form>
            </div>
        </div>
    );
}

export default Part5;
import './aboutUs.css';
import town from './town.png';
import ceo from './ceo.png';
import fullTown from './fullTown.png'

function AboutUs({changeBlock}) {
    return (
        <div id="AboutUs">
            <div id="about1">
                <div>
                    <h1>About Us</h1>
                    <p>Afdal Tariqah Trading LLC is a company engaged in international wholesale trade of construction materials. We organize supply chains for the comprehensive supply of construction projects from excavation work and foundation construction to building façade cladding and interior finishing work. Decades of experience in the production of building materials, organizing trade activities, as well as the acquired expertise, allows us to solve the most complex problems in the integrated supply of construction projects. We understand the complexity of creating supply chains from different sources located in different countries, so we have created a simple and effective way to do this. The main warehouse facilities are located in the UAE, which is an ideal point on the map to ensure prompt delivery of everything necessary for the implementation of your projects. A carefully selected staff of design engineers will help you bring your most daring projects to life.</p>
                </div>
                <img src={town} alt="" id='halfTown'/>
                <img src={fullTown} alt="" id='fullTown'/>
            </div>
            <div id='about2'>
                <img src={ceo} alt="" />
                <div>
                    <h2>Message from CEO</h2>
                    <p>We created Afdal Tariqah to provide the comfort that is so necessary in this complex process of designing and creating a construction project. We understand the responsibility and importance of this process. Our main goal is to simplify the delivery process, allowing you to deal with the really important stages of making the project a reality. Our team is not afraid of complicated tasks and is always ready to help in the most difficult moments. If you are looking for a reliable supplier, welcome to join our company workflow.</p>
                    <span>Our values</span>
                    <div>
                        <div className='unorderList'>
                            <div></div>
                            <span>Compliance with consumer expectations;</span>
                        </div>
                        <div className='unorderList'>
                            <div></div>
                            <span>The high level of quality control;</span>
                        </div>
                        <div className='unorderList'>
                            <div></div>
                            <span>Speed and stability of the products;</span>
                        </div>
                        <div className='unorderList'>
                            <div></div>
                            <span>Implementation of innovations in our products.</span>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export default AboutUs;